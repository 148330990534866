/*
 * @Description: 
 * @Author: huyue
 * @Date: 2023-01-30 16:10:23
 * @LastEditors: huyue
 * @LastEditTime: 2023-11-09 18:01:20
 */
/**
 * 公共配置模块
 */
export default {
  baseUrl: {
    dev: "/api",
    pro: "https://config-center-backend.glsx.com.cn", //生产环境
    // pro: "https://config-center-backend.test.glsx.com.cn", //test环境
    // pro: "http://192.168.1.68:7003"
  },
};
